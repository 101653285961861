import React from "react"
import "prismjs/themes/prism-okaidia.css"

import GlobalDataProvider from "./src/provider/GlobalDataProvider"

export const wrapRootElement = ({ element }) => {
  return (
    <GlobalDataProvider>
      {element}
    </GlobalDataProvider>
  )
}