import React from "react"

const initialData = {
  posts: {},
  addPosts: (path, newPosts) => { },
}

export const GlobalDataContext = React.createContext(initialData)

const GlobalDataProvider = (props) => {
  const [posts, setPosts] = React.useState(initialData.posts)

  const addPosts = (path, newPosts) => {
    const oldPosts = posts[path] ? posts[path] : []
    setPosts(
      Object.assign(
        {},
        posts,
        {
          [path]: [...oldPosts, ...newPosts],
        },
      ),
    )
  }

  return (
    <GlobalDataContext.Provider
      value={{
        posts,
        addPosts,
      }}
    >
      {props.children}
    </GlobalDataContext.Provider>
  )
}

export default GlobalDataProvider